import { Button } from '@mui/material';
import SearchInput from 'components/SearchInput';
import ArrearTable from 'components/ui/tables/ArrearTable';
import useTopPanel from 'hooks/useTopPanel';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ArrearsFilterDrawer from 'components/dashboard/ArrearsFilterDrawer';
import SearchInputNavbar from 'components/SearchInputNavbar';

const Arrears = () => {
  const { setComponent } = useTopPanel();
  const { t, i18n } = useTranslation();
  const [blocksError, setBlocksError] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  useEffect(() => {
    setComponent(<div className='component-title'>{t('arrears.title')}</div>);
  }, [i18n.language]);

  const [refetch, setRefetch] = useState(false);
  const [open, setOpen] = useState(false);

  return (
    <div className='component-list-wrapper'>
      <div className='component-list-header mb-2'>
        <div className='header-actions-container pt-2 pb-2 flex flex-row items-center'>
          <div className='header-actions filter-box flex items-center'>
            <Button
              variant='base'
              startIcon={<i className='bi bi-filter' />}
              className='!mr-2'
              onClick={() => setOpenFilter((prev) => !prev)}
            >
              {t('common.button.filter')}
            </Button>
          </div>
          <div className='grow'>
            <SearchInputNavbar inputKey='name' fullWidth />
          </div>
          <div className='header-actions action-buttons-box flex items-center justify-center ml-4'>
            <Button
              variant='base'
              onClick={() => {
                setRefetch(true);
              }}
              disable={`${refetch}`}
            >
              <i
                className={`bi bi-arrow-repeat${
                  refetch ? ' animate-spin' : ''
                }`}
              />
            </Button>
            <Link
              to={
                blocksError
                  ? '#'
                  : `${process.env.REACT_APP_BACKEND_URL}/excel/debitors`
              }
              // target='_blank'
              className='no-underline'
            >
              <Button variant='base' className='!ml-2' disabled={blocksError}>
                <i className='bi bi-download' />
              </Button>
            </Link>
            <Button
              variant='base'
              className='!ml-2'
              onClick={() => setOpen(true)}
            >
              <i className='bi bi-gear' />
            </Button>
          </div>
        </div>
      </div>

      <div className='component-table-wrapper h-[calc(100vh-160px)]'>
        <ArrearTable
          emitRefetch={{ refetch, setRefetch }}
          sorting={true}
          dataPath='dictionary/debitors'
          emitTableColumns={{ open, setOpen }}
          tableName='arrears'
          headCells={[
            { code: 'id', label: '#' },
            { code: 'name', label: t('common.table.contractName') },
            { code: 'custom', label: t('common.table.custom') },
            { code: 'phone', label: t('common.table.phone') },
            { code: 'sum', label: t('common.table.arrears') },
            { code: 'date', label: t('common.table.debtPaymentDate') },
          ]}
          columns={[
            { code: 'id' },
            { code: 'name' },
            { code: 'custom' },
            {
              code: 'phone',
              type: 'multipleFields',
              fields: ['phone', 'phone2'],
            },
            { code: 'sum' },
            { code: 'date', type: 'date' },
          ]}
          actionContractView={true}
        />
      </div>
      <ArrearsFilterDrawer open={openFilter} setOpen={setOpenFilter} />
    </div>
  );
};

export default memo(Arrears);
